<div class="container-fluid" style="padding-top: 100px; padding-bottom: 60px;"> 
  <div class="row justify-content-center align-items-center" style="min-height: calc(100vh - 160px);"> 
    <div class="col">
      <div class="card shadow-2-strong" style="border-radius: 15px;">
        <div class="card-body p-4 p-md-5 text-center" style="overflow-y: auto;max-height: 600px;"> 
          <form [formGroup]="recordForm" (ngSubmit)="addRecord()" class="record-form">
            <div class="form-group">
              <label for="time">Time:</label>
              <input type="time" class="form-control" formControlName="time">
            </div>
            <div class="form-group">
              <label for="name">Name:</label>
              <select class="form-control" formControlName="name" [(ngModel)]="selectedUser" (ngModelChange)="onUserChange($event)">
                  <option *ngFor="let user of users" [ngValue]="user">{{user.firstName}} {{user.secondName}}</option>
                </select>          
            </div>
            <div class="form-group">
              <label for="subject">Subject (Presentation Title):</label>
              <input type="text" class="form-control" formControlName="subject" placeholder="Presentation Title" readonly>
            </div>
            <div class="row mb-3">
                <button type="submit" class="btn btn-primary w-100">Add Record</button>
                <button (click)="saveAsPDF()" class="btn btn-info w-100 save-pdf-btn">Save as PDF</button>
            </div>
          </form>
            
          <div #recordsTable class="table-responsive scrollable-table record-table">
            <table class="table">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Name</th>
                  <th>Subject</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let record of records; let i = index">
                  <td>
                    <div *ngIf="editingIndex === i; else viewModeTime">
                      <input [(ngModel)]="tempTime" type="text" class="form-control">
                    </div>
                    <ng-template #viewModeTime>{{record.time}}</ng-template>
                  </td>
                  <td>
                    <div *ngIf="editingIndex === i; else viewModeName">
                      <input [(ngModel)]="tempName" type="text" class="form-control">
                    </div>
                    <ng-template #viewModeName>{{record.name}}</ng-template>
                  </td>                  
                  <td>
                    <div *ngIf="editingIndex === i; else viewModeSubject">
                      <input [(ngModel)]="tempSubject" type="text" class="form-control">
                    </div>
                    <ng-template #viewModeSubject>{{record.subject}}</ng-template>
                  </td>
                  <td>
                    <div *ngIf="editingIndex === i; else editAndDeleteButtons">
                      <button class="btn btn-success" (click)="saveChanges(i)">Save</button>
                      <button class="btn btn-secondary" (click)="cancelEdit()">Cancel</button>
                    </div>
                    <ng-template #editAndDeleteButtons>
                      <button class="btn btn-info" (click)="editRecord(i)">Edit</button>
                      <button class="btn btn-danger" (click)="deleteRecord(i)">Delete</button>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>