<div class="container-fluid" style="padding-top: 100px; padding-bottom: 60px;"> 
  <div class="row justify-content-center align-items-center" style="min-height: calc(100vh - 160px);"> 
    <div class="col">
      <div class="card shadow-2-strong" style="border-radius: 15px;">
        <div class="card-body p-4 p-md-5 text-center" style="overflow-y: auto;max-height: 600px;">
          <h2 class="mb-4">Seminarium na temat:</h2>
          <p class="lead mb-4">
            "ZASTOSOWANIE SZTUCZNEJ INTELIGENCJI W SYMULACJI I STEROWANIU PROCESAMI METALURGICZNYMI"
          </p>

          <p class="mb-4">
            Odbędzie się 18 kwietnia 2024 w Akademii Górniczo-Hutniczej im. Stanisława Staszica w Krakowie.
          </p>

          <p class="mb-4">
            Tegoroczne Seminarium NeuroMet będzie dwudziestym szóstym z serii spotkań, których celem jest stworzenie platformy do wymiany doświadczeń
            w zakresie zastosowania sztucznej inteligencji do symulacji i sterowania procesami metalurgicznymi.
          </p>

          <p class="mb-4">
            Zachęcamy do zgłoszeń w przyszłym roku w tematyce uczenia maszynowego, Industry 4.0, analizy danych i optymalizacji. Zapraszamy do
            prezentacji wyników badań, wyników prac badawczych i wdrożeniowych oraz tematyk badawczych doktoratów. Udział w seminarium jest bezpłatny.
          </p>

          <p class="mb-4">
            Zapraszamy!
          </p>
          <div class="organizer-and-image d-flex justify-content-between align-items-center">
            <div>
              <strong>ORGANIZATOR SEMINARIUM</strong><br>
              Katedra Informatyki Stosowanej i Modelowania<br>
              Przewodniczący Komitetu Organizacyjnego<br>
              dr hab. inż. Krzysztof Regulski, prof. AGH
            </div>
            <div class="contact-images">
              <img src="assets/aghLogo.png" alt="Description of Image 2" class="contact-image">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>