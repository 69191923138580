<div class="container-fluid">
  <div class="row justify-content-center align-items-center vh-100">
    <div class="card shadow-2-strong card-registration" style="border-radius: 15px;">
      <div class="card-body p-4 p-md-5">
        <div class="button-container">
          <button class="btn btn-primary mb-3" (click)="openPdf()">Otwórz Program PDF</button>
        </div>
      </div>
    </div>
  </div>
</div>
