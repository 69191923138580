<div class="container-fluid" style="padding-top: 100px; padding-bottom: 60px;"> 
  <div class="row justify-content-center align-items-center" style="min-height: calc(100vh - 160px);"> 
    <div class="col">
      <div class="card shadow-2-strong" style="border-radius: 15px;">
        <div class="card-body p-4 p-md-5 text-center" style="overflow-y: auto;max-height: 600px;">

          <form *ngIf="!registrationCompleted" [formGroup]="registerForm" (ngSubmit)="register()" autocomplete="off">
            <h2 class="text-center">Rejestracja</h2>
            <hr>
              
            <div class="form-group">
              <input type="text" class="form-control" formControlName="firstName" placeholder="Imię">
              <small *ngIf="registerForm.controls.firstName.touched && registerForm.controls.firstName.errors" class="text-danger">
                <div *ngIf="registerForm.controls.firstName.errors.required">Imię jest wymagany.</div>
                <div *ngIf="registerForm.controls.firstName.errors.minlength">Imię musi mieć przynajmniej 2 znaki.</div>
                <div *ngIf="registerForm.controls.firstName.errors.maxlength">Imię nie może być dłuższy niż 50 znaków.</div>
              </small>
            </div>

            <div class="form-group">
              <input type="text" class="form-control" formControlName="secondName" placeholder="Nazwisko">
              <small *ngIf="registerForm.controls.secondName.touched && registerForm.controls.secondName.errors" class="text-danger">
                <div *ngIf="registerForm.controls.secondName.errors.required">Nazwisko jest wymagany.</div>
                <div *ngIf="registerForm.controls.secondName.errors.minlength">Nazwisko musi mieć przynajmniej 2 znaki.</div>
                <div *ngIf="registerForm.controls.secondName.errors.maxlength">Nazwisko nie może być dłuższy niż 50 znaków.</div>
              </small>
            </div>
            
              <div class="form-group">
                <input type="email" class="form-control" formControlName="emailAddress" placeholder="E-mail">
                <small *ngIf="registerForm.controls.emailAddress.touched && registerForm.controls.emailAddress.errors" class="text-danger">
                  <div *ngIf="registerForm.controls.emailAddress.errors.required">Email jest wymagany.</div>
                  <div *ngIf="registerForm.controls.emailAddress.errors.email">Zły format adresu E-mail.</div>
                </small>
              </div>
            
              <div class="form-group">
                <input type="text" class="form-control" formControlName="university" placeholder="Uczelnia">
                <small *ngIf="registerForm.controls.university.touched && registerForm.controls.university.errors" class="text-danger">
                  <div *ngIf="registerForm.controls.university.errors.required">Uczelnia jest wymagany.</div>
                  <div *ngIf="registerForm.controls.university.errors.minlength">Uczelnia musi mieć przynajmniej 2 znaki.</div>
                  <div *ngIf="registerForm.controls.university.errors.maxlength">Uczelnia nie może być dłuższy niż 50 znaków.</div>
                </small>
              </div>

              <div class="form-group">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="presentationCheckbox" (change)="togglePresentationField()">
                  <label class="form-check-label" for="presentationCheckbox">Prezentacja</label>
                </div>
              </div>
              
              <div class="form-group">
                <input type="text" class="form-control" formControlName="presentation" placeholder="Tytuł prezentacji">
                <small *ngIf="registerForm.controls.presentation.touched && registerForm.controls.presentation.errors" class="text-danger">
                  <div *ngIf="registerForm.controls.presentation.errors.required">Tytuł prezentacji jest wymagany.</div>
                  <div *ngIf="registerForm.controls.presentation.errors.minlength">Tytuł prezentacji musi mieć przynajmniej 2 znaki.</div>
                  <div *ngIf="registerForm.controls.presentation.errors.maxlength">Tytuł prezentacji nie może być dłuższy niż 100 znaków.</div>
                </small>
              </div>
              

            
              <div class="form-group text-center">
                <div class="text-center"> 
                  <re-captcha 
                    (resolved)="resolved($event)" 
                    [siteKey]="siteKey"
                    formControlName="recaptchaReactive">
                  </re-captcha>
                </div>
                
                <button class="btn btn-success mr-2" type="submit" [disabled]="registerForm.invalid">Rejestruj</button>
                <button class="btn btn-default mr-2" (click)="cancel()" type="button">Anuluj</button>
              </div>
            </form>
            
            <div *ngIf="registrationCompleted" class="alert alert-success mt-3">
              Rejestracja zakończona!
            </div>

        </div>
      </div>
    </div>
</div>
