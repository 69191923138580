<div class="container-fluid" style="padding-top: 100px; padding-bottom: 60px;"> 
  <div class="row justify-content-center align-items-center" style="min-height: calc(100vh - 160px);"> 
    <div class="col">
      <div class="card shadow-2-strong" style="border-radius: 15px;">
        <div class="card-body p-4 p-md-5 text-center" style="overflow-y: auto; max-height: 600px;">
          <div>
            <h2 class="mb-4">KONTAKT</h2>
            <p>dr hab. inż. Krzysztof Regulski, prof. AGH</p>
            <p>tel.: (0-12) 617 41 31</p>
            <p>e-mail: <a href="mailto:regulski@agh.edu.pl">regulski&#64;agh.edu.pl</a></p>
          </div>
          <hr class="my-4">
          <div>
            <p>dr inż. Łukasz Sztangret</p>
            <p>tel.: (0-12) 617 41 83</p>
            <p>e-mail: <a href="mailto:szt@agh.edu.pl">szt&#64;agh.edu.pl</a></p>
          </div>
          <hr class="my-4">
          <div>
            <p>Akademia Górniczo-Hutnicza w Krakowie</p>
            <p>Wydział Inżynierii Metali i Informatyki Przemysłowej</p>
            <p>Katedra Informatyki Stosowanej i Modelowania</p>
            <div class="contact-images">
              <img src="assets/isim.png" alt="Description of Image 1" class="contact-image">
              <img src="assets/aghLogo.png" alt="Description of Image 2" class="contact-image">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
