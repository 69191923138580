<div class="container-fluid" style="padding-top: 100px; padding-bottom: 60px;"> 
  <div class="row justify-content-center align-items-center" style="min-height: calc(100vh - 160px);"> 
    <div class="col">
      <div class="card shadow-2-strong" style="border-radius: 15px;">
        <div class="card-body p-4 p-md-5 text-center" style="overflow-y: auto;max-height: 600px;">
  
            <form #loginForm="ngForm" class="form-signin" (ngSubmit)="login()" autocomplete="off">
              <h1 class="h3 mb-3 font-weight-normal">Login</h1>
  
              <label for="inputUsername" class="sr-only">Username</label>
              <input
                name="username"
                [(ngModel)]="model.username"
                class="form-control mb-3"
                type="text"
                id="inputUsername"
                placeholder="Username"
                required
                autofocus
              >
  
              <label for="inputPassword" class="sr-only">Password</label>
              <input
                name="password"
                [(ngModel)]="model.password"
                class="form-control mb-3"
                type="password"
                id="inputPassword"
                placeholder="Password"
                required
              >
  
              <button class="btn btn-lg btn-success btn-block" type="submit">Login</button>
            </form>
            
          </div>
        </div>
      </div>
    </div>
  </div>
  